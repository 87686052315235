.home-page {
  .landing-blue-search-section {
    @include mediaResponsive(sm) {
      padding-bottom: 40px;
    }
  }
  .section-spacer-home {
    padding: 20px 0px 20px 0;
  }
  .section-spacer-home-banner {
    padding: 60px 0;
  }
  .section-spacer-home-banner-login {
    @include mediaResponsive(lg) {
      padding: 125px 0 0 0;
    }
  }
  .banner-section {
    &.vh-lg-100 {
      @include mediaResponsive(mLg) {
        height: 100%;
      }
    }
    @extend .common-banner-section;
    &.home-main {
      background-size: 100% 100%;
      @include mediaResponsive(lg) {
        background: none;
      }
    }
    .home-banner-img {
      @extend .common-banner-img;
      width: 650px;
      height: 80%;
      margin-top: 4%;
    }
    .form-control-placeholder {
      font-weight: 500 !important;
    }
  }

  .about-us-section {
    .about-banner {
      &-img {
        max-width: 100%;
      }
    }
  }

  .search-section {
    .section-title {
      font-size: 40px;
      @include mediaResponsive(md) {
        font-size: 30px;
      }
      @include mediaResponsive(sm) {
        font-size: 24px;
      }
    }
    .home-blue-search-section {
      position: relative;
      padding-top: 20px;
      padding-bottom: 40px;
      width: 850px;
      margin: 0 auto;
      background-image: url("#{$img_s3_url}images/Call_to_action_bg.jpg");
      background-size: cover;
      background-position: right top;
      background-repeat: no-repeat;
      // background-color: $blue;
      color: $white;
      @include mediaResponsive(lg) {
        width: 700px;
      }
      @include mediaResponsive(md) {
        width: auto;
      }
      @include mediaResponsive(sm) {
        padding-bottom: 5px;
      }
    }
  }

  .line-container {
    background-image: none !important;
  }

  .what-we-do {
    // background-image: url("#{$img_s3_url}images/What_we_do_line.png");
    // background-image: url(../../images/bgLine.png);
    // background-size: 100% 40%;
    // background-position: center 20%;
    // background-repeat: no-repeat;
    margin-bottom: 75px;
    @include mediaResponsive(lg) {
      background-image: none;
      margin-bottom: 20px;
    }
    &-title {
      h4 {
        font-size: 20px;
      }
      @include mediaResponsive(md) {
        h4 {
          font-size: 18px;
        }
      }
    }
    .home-box {
      &.box-1,
      &.box-2,
      &.box-3,
      &.box-4 {
        .what-we-do-icons.mx-auto {
          background-image: url("#{$img_s3_url}images/icon_bg.png") !important;
        }
      }
    }
    &-icons {
      margin-left: -10px;
      height: 140px;
      width: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url("#{$img_s3_url}images/icon_bg.png") !important;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      @include mediaResponsive(md) {
        margin: 0 auto;
        height: fit-content;
        i.text-blue {
          &::before {
            font-size: 30px !important;
          }
        }
      }
      i.text-blue {
        &::before {
          margin-left: 0;
          font-size: 60px;
        }
      }
    }
    &-box {
      margin-left: 10px;
      margin-right: 10px;
      &.odd-box {
        top: 60px;
        position: relative;
        @include mediaResponsive(lg) {
          top: 0;
        }
        @include mediaResponsive(md) {
          margin-top: 20px;
        }
      }
      &.home-box-3 {
        top: 85px;
        position: relative;
        @include mediaResponsive(lg) {
          top: 0;
        }
        @include mediaResponsive(md) {
          margin-top: 20px;
        }
      }
      &.even-box {
        top: 35px;
        position: relative;
        @include mediaResponsive(lg) {
          top: 0;
        }
        @include mediaResponsive(md) {
          margin-top: 20px;
        }
        &-last {
          top: 30px;
          position: relative;
          @include mediaResponsive(lg) {
            top: 0;
          }
        }
      }
    }
  }
  .promoted-location-title {
    display: block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.featured-row {
  @include mediaResponsive(sm) {
    max-width: 100% !important;
    margin: 0 auto !important;
  }
  justify-content: center;
}
